import { createContext } from '@/utils';

export enum Mode {
  Author = 'author',
  Respond = 'respond',
  Submit = 'submit',
}

type ExecutionContextType = {
  mode: Mode;
  setMode: (mode: Mode) => void;
};

export const ExecutionContext = createContext<ExecutionContextType>(
  {
    mode: Mode.Respond,
    setMode: () => {},
  },
  'ExecutionContext'
);
