import { useQueryParams } from '@/hooks/useQueryParams';

export enum Mode {
  Schedule = 'schedule',
  Responses = 'responses',
}
export const MODES = Object.values(Mode);

const QUERY_PARAM = 'mode';

const getActiveMode = (param: string | null): Mode => {
  if (param && MODES.includes(param as Mode)) {
    return param as Mode;
  }
  return MODES[0];
};

export const useActiveMode = () => {
  const { queryParams, setQueryParams, removeQueryParam } = useQueryParams();
  const activeMode = getActiveMode(queryParams.get(QUERY_PARAM));
  const activeIndex = MODES.indexOf(activeMode);

  const setMode = (nextMode: Mode) => {
    if (nextMode === MODES[0]) {
      removeQueryParam(QUERY_PARAM);
    } else {
      setQueryParams({ [QUERY_PARAM]: nextMode });
    }
  };

  return {
    activeMode,
    activeIndex,
    setMode,
  };
};
