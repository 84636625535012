import { useQueryParams } from '@/hooks/useQueryParams';

export const SORT = ['date', 'responder'] as const;

export type Sort = (typeof SORT)[number];

const QUERY_PARAM = 'sort';

const getSort = (param: string | null): Sort => {
  if (param && SORT.includes(param as Sort)) {
    return param as Sort;
  }
  return SORT[0];
};

export const useSort = () => {
  const { queryParams, setQueryParams, removeQueryParam } = useQueryParams();
  const activeSort = getSort(queryParams.get(QUERY_PARAM));
  const activeIndex = SORT.indexOf(activeSort);

  const setSort = (nextSort: Sort) => {
    if (nextSort === SORT[0]) {
      removeQueryParam(QUERY_PARAM);
    } else {
      setQueryParams({ [QUERY_PARAM]: nextSort });
    }
  };

  return {
    activeSort,
    activeIndex,
    setSort,
  };
};
