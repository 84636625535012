import { useTranslation } from 'react-i18next';
import {
  summarizeTime,
  toDateFormat,
} from '@/pages/Schedule/components/ItemCard/utils';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import {
  formatTime,
  needsConsolidatedSummary,
} from '@/pages/Schedule/views/ConflictView/lib/summary';
import { Box, Flex, type FlexProps, Text } from '@/ui';

type Props = {
  entry: DecoratedEntry;
  instance: DecoratedInstance;
} & FlexProps;

export const EntryDetails = ({ entry, instance, ...flexProps }: Props) => {
  const { t } = useTranslation('schedule');

  const emoji = instance.emoji || entry.emoji;
  const title =
    instance.title || entry.title || t('untitled_entry', { ns: 'common' });

  return (
    <Flex align="flex-start" gap="6" grow="1" {...flexProps}>
      <Box
        fontSize="3xl"
        lineHeight="1.1"
        pos="relative"
        textAlign="center"
        w="20px"
      >
        {emoji}
      </Box>

      <Flex direction="column" gap="0" mt="-1">
        <Text data-title fontWeight="bold">
          {title}
        </Text>
        <Flex direction="column">
          <Text _firstLetter={{ textTransform: 'capitalize' }}>
            {needsConsolidatedSummary(entry)
              ? summarizeTime(entry, instance)
              : toDateFormat(instance.startDate)}
          </Text>
          {!needsConsolidatedSummary(entry) && (
            <Text _firstLetter={{ textTransform: 'capitalize' }}>
              {formatTime(entry, instance)}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
