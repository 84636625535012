import { type ReactNode, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { ExecutionContext, Mode } from './ExecutionContext';

type Props = {
  children: ReactNode;
};

export const ExecutionContextProvider = ({ children }: Props) => {
  const respondMatch = useMatch('/schedule/:slug/respond');
  const routeMode: Mode = respondMatch ? Mode.Respond : Mode.Author;
  const [mode, setMode] = useState<Mode>(routeMode);

  return (
    <ExecutionContext.Provider value={{ mode, setMode }}>
      {children}
    </ExecutionContext.Provider>
  );
};
