import { AvailabilityType } from '@/types/gql.generated';
import { Card, CardBody, CardHeader, Flex, Heading, Text } from '@/ui';
import type { AvailabilityResponseGroup } from '../../utils';
import { GroupHeading } from '../GroupHeading';
import { GroupList } from '../GroupList';
import { ResponseContainer } from '../ResponseContainer';
import { ResponseConflict } from './ResponseConflict';

type Props = {
  group: AvailabilityResponseGroup;
};

export const ResponseCard = ({ group }: Props): JSX.Element => {
  const fullConflicts = group.items.filter(
    (item) => item.status === AvailabilityType.Full
  );
  const partialConflicts = group.items.filter(
    (item) => item.status === AvailabilityType.Partial
  );

  return (
    <Card as="article" key={group.id}>
      <CardHeader
        bg="#fbfbfd"
        borderBottom="solid 1px rgba(0, 0, 0, 0.10)"
        pb="0"
      >
        <Flex align="center" gap="4" justify="space-between" pb="4">
          <Heading as="h3" color="dark.600" fontWeight="bold" size="sm">
            {group.name}
          </Heading>
          <Text fontSize="sm" variant="mid">
            {group.submittedAt.toLocaleString()}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        {group.items.length === 0 ? (
          <GroupHeading status={null} title="No conflicts" />
        ) : (
          <ResponseContainer>
            <GroupList>
              <GroupHeading
                status={AvailabilityType.Full}
                title={`Full conflicts (${fullConflicts.length})`}
              />
              {fullConflicts.map((item) => (
                <ResponseConflict item={item} key={item.id} />
              ))}
            </GroupList>
            <GroupList>
              <GroupHeading
                status={AvailabilityType.Partial}
                title={`Partial conflicts (${partialConflicts.length})`}
              />
              {partialConflicts.map((item) => (
                <ResponseConflict item={item} key={item.id} />
              ))}
            </GroupList>
          </ResponseContainer>
        )}
      </CardBody>
    </Card>
  );
};
