import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib/gql-client';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { AvailabilityResponseInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { getDateString } from '../lib/instance';
import type {
  SubmitAvailabilityResponseMutation,
  SubmitAvailabilityResponseMutationVariables,
} from './useAvailabilitySubmit.generated';

const query = gql`
  mutation SubmitAvailabilityResponse(
    $scheduleId: ID!
    $response: AvailabilityResponseInput!
  ) {
    submitAvailability(scheduleId: $scheduleId, response: $response) {
      id
      submittedAt
      name
      email
      items {
        id
        instance
        status
        note
        entry {
          id
          title
          timeZone
        }
      }
    }
  }
`;

export const useAvailabilitySubmit = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...result } = useMutation<
    SubmitAvailabilityResponseMutation,
    QueryError,
    SubmitAvailabilityResponseMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        SubmitAvailabilityResponseMutation,
        SubmitAvailabilityResponseMutationVariables
      >(query, variables);
    },
  });

  const respond = (
    response: AvailabilityResponseInput,
    options?: MutateOptions<
      SubmitAvailabilityResponseMutation,
      QueryError,
      SubmitAvailabilityResponseMutationVariables
    >
  ) => {
    const variables: SubmitAvailabilityResponseMutationVariables = {
      scheduleId,
      response: {
        ...response,
        items: response.items.map((item) => {
          return {
            ...item,
            instance: getDateString(item.instance),
          };
        }),
      },
    };
    return mutate(variables, options);
  };

  return {
    ...result,
    respond,
  };
};
