import { MdOutlineCancel, MdOutlineCheckCircleOutline } from 'react-icons/md';
import { AvailabilityType } from '@/types/gql.generated';
import { Icon, type IconProps } from '@/ui';
import { PartialConflictIcon } from './assets/PartialConflict';

type Props = {
  status: AvailabilityType | null;
} & IconProps;

export const ConflictIcon = ({ status, ...iconProps }: Props) => {
  if (status === AvailabilityType.Full) {
    return (
      <Icon
        as={MdOutlineCancel}
        boxSize="20px"
        color="red.500"
        {...iconProps}
      />
    );
  }
  if (status === AvailabilityType.Partial) {
    return (
      <Icon
        as={PartialConflictIcon}
        boxSize="20px"
        color="gray.900"
        {...iconProps}
      />
    );
  }
  return (
    <Icon
      as={MdOutlineCheckCircleOutline}
      boxSize="20px"
      color="green.500"
      {...iconProps}
    />
  );
};
