import { type ReactNode, useState } from 'react';
import type { DecoratedInstance } from '@/pages/Schedule/types';
import type { AvailabilityType } from '@/types/gql.generated';
import type { Availability, AvailabilityStateItem } from '../../types';
import { AvailabilityContext } from './AvailabilityContext';

type Props = {
  children: ReactNode;
};

export const AvailabilityContextProvider = ({ children }: Props) => {
  const [items, setItems] = useState<AvailabilityStateItem[]>([]);

  const setAvailability = (
    instance: DecoratedInstance,
    availability: Partial<Availability>
  ) => {
    let isEdit = false;
    let updatedItems = items.map((item) => {
      if (item.instance === instance.id) {
        isEdit = true;
        return { ...item, ...availability };
      }
      return item;
    });
    if (!isEdit) {
      updatedItems = [
        ...items,
        { instance: instance.id, entryId: instance.parentId, ...availability },
      ];
    }

    setItems(updatedItems);
  };

  const clearAvailability = (instance: DecoratedInstance) => {
    setItems(
      items.filter(
        (item) =>
          item.entryId !== instance.parentId && item.instance !== instance.id
      )
    );
  };

  const getConflictForInstance = (
    instance: DecoratedInstance
  ): AvailabilityStateItem | undefined => {
    return items.find(
      (item) =>
        item.entryId === instance.parentId && item.instance === instance.id
    );
  };

  const hasConflict = (
    instance: DecoratedInstance,
    status: AvailabilityType
  ): boolean => {
    return items.some(
      (item) =>
        item.entryId === instance.parentId &&
        item.instance === instance.id &&
        item.status === status
    );
  };

  return (
    <AvailabilityContext.Provider
      value={{
        items,
        setAvailability,
        clearAvailability,
        getConflictForInstance,
        hasConflict,
      }}
    >
      {children}
    </AvailabilityContext.Provider>
  );
};
