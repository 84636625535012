import type { ReactNode } from 'react';
import { Text } from '@/ui';

type Props = {
  children: ReactNode;
};

export const Title = ({ children }: Props) => {
  return (
    <Text as="label" display="block" fontWeight="bold">
      {children}
    </Text>
  );
};
