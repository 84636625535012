import { useMeasure } from 'react-use';
import {
  Box,
  RadioGroup,
  type RadioGroupProps,
  Flex,
  Radio,
  forwardRef,
} from '@/ui';

type Option<T> = {
  isDisabled?: boolean;
  label: string;
  value: T;
};

type SwitchProps<T> = Omit<RadioGroupProps, 'onChange' | 'children'> & {
  value: T;
  options: Option<T>[];
  onChange: (value: T) => void;
};

type TabProps = {
  value: string;
  isDisabled?: boolean;
};

const TAB_GAP = 2;

const Tab = forwardRef(({ value, isDisabled, ...props }: TabProps, ref) => {
  return (
    <>
      <Radio display="none" id={value} isDisabled={isDisabled} value={value} />

      <Flex
        _hover={{ bg: 'rgba(255,255,255,0.7)' }}
        align="center"
        as="label"
        bg="transparent"
        border="none"
        borderRadius="md"
        color="black"
        cursor="pointer"
        flex="1"
        fontSize="sm"
        fontWeight="bold"
        h="9"
        htmlFor={value}
        justify="center"
        ref={ref}
        transition="all 0.1s ease-in-out"
        w="5rem"
        zIndex="2"
        {...(isDisabled && {
          color: 'gray.600',
          cursor: 'not-allowed',
          _hover: undefined,
        })}
        {...props}
      />
    </>
  );
});

export const Switch = <T extends string>({
  value,
  options,
  ...props
}: SwitchProps<T>) => {
  const [ref, { width, height }] = useMeasure();

  const gliderX =
    value === options[0].value
      ? 0
      : `calc(100% + var(--chakra-space-${TAB_GAP}))`;

  return (
    <RadioGroup
      bg="#E0EBF5"
      borderRadius="md"
      defaultValue={value}
      display="flex"
      gap={TAB_GAP}
      p="1"
      pos="relative"
      {...props}
    >
      {options.map((option) => (
        <Tab
          isDisabled={option.isDisabled}
          key={option.value}
          ref={value === option.value ? ref : undefined}
          value={option.value as string}
        >
          {option.label}
        </Tab>
      ))}

      <Box
        bg="white"
        borderRadius="md"
        h={height + 'px'}
        pos="absolute"
        shadow="0px 0px 8px 2px rgba(0, 0, 0, 0.10)"
        transform={`translateX(${gliderX})`}
        transition="transform 0.25s ease-in-out"
        w={width + 'px'}
        zIndex="1"
      />
    </RadioGroup>
  );
};
