import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PasswordInput, useAuthenticate } from '@/components/auth';
import { ResetPasswordLink } from '@/components/auth/ResetPasswordLink';
import { useCurrentUserContext } from '@/contexts';
import { AuthAction } from '@/types/gql.generated';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@/ui';
import { getErrorMessage } from '@/utils/errors';

type Props = {
  email: string;
  name: string;
};

type FormValues = {
  email: string;
  name: string;
  password: string;
};

export const PasswordLogin = ({ email, name }: Props): JSX.Element => {
  const { t } = useTranslation('auth');
  const { currentUser } = useCurrentUserContext();
  const navigate = useNavigate();
  const {
    authenticate,
    isLoading: isAuthenticating,
    error: authError,
  } = useAuthenticate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email,
      name,
      password: '',
    },
  });

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const login = (data: FormValues): void => {
    authenticate(AuthAction.LoginPassword, data, {
      // setTimeout is needed here to allow currentUserContext to refresh with
      // new user. Otherwise Dashboard will kick back out to /auth
      onSuccess: () => setTimeout(() => navigate('/'), 0),
    });
  };

  return (
    <Flex as="form" direction="column" gap="3" onSubmit={handleSubmit(login)}>
      <Text
        fontSize={{ base: 'md', sm: '18px' }}
        lineHeight="1.4"
        maxW="500px"
        mb="6"
        px={{ md: 5 }}
      >
        We&apos;re on a mission to save people time (and clicks). Sign in (to{' '}
        {email}) to access your account.
      </Text>
      {authError && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{getErrorMessage(authError)}</AlertTitle>
        </Alert>
      )}

      <input autoComplete="username" type="hidden" value={email} />
      <FormControl isInvalid={!!errors.password} variant="floating">
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <PasswordInput
              autoComplete="current-password"
              autoFocus
              bg="white"
              id="password"
              placeholder={t('password')}
              {...field}
            />
          )}
          rules={{
            validate: {
              password: (value) => {
                if (!value.trim().length) {
                  return t('validation.required');
                }
                if (value.length < 8) {
                  return t('validation.password_too_short');
                }
              },
            },
          }}
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>

      <ResetPasswordLink
        alignSelf="flex-start"
        fontSize="sm"
        width="fit-content"
      />
      <Button isLoading={isAuthenticating} type="submit">
        {t('sign_in')}
      </Button>
    </Flex>
  );
};
