import { Flex, type FlexProps, forwardRef } from '@/ui';

export const Card = forwardRef((props: FlexProps, ref) => {
  return (
    <Flex
      bg="white"
      borderRadius="xl"
      p={{ base: 3, md: 5 }}
      ref={ref}
      shadow="0px 0px 30px 2px rgba(0, 0, 0, 0.10)"
      w="100%"
      {...props}
    />
  );
});
