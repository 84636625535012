import { Logo } from '@/components/Logo';
import { Link, Flex, Box, type FlexProps } from '@/ui';

export const Footer = (props: FlexProps) => {
  return (
    <Flex
      as="footer"
      direction="column"
      gap="2"
      mt="auto"
      pt="4"
      textAlign="center"
      {...props}
    >
      <Link
        _hover={{ textDecor: 'none', bg: 'gray.100' }}
        alignItems="center"
        bg="#E7E3F5"
        borderRadius="70px"
        color="customgray.dark"
        display="flex"
        fontWeight="bold"
        gap="2"
        href="/"
        lineHeight="normal"
        mx="auto"
        px="3"
        py="2"
      >
        Powered by <Logo width={20} /> agendahero
      </Link>
      <Box>
        <Link
          color="currentColor"
          fontSize="sm"
          href="mailto:support@agendahero.com"
        >
          Contact us
        </Link>
      </Box>
    </Flex>
  );
};
