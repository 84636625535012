import type { ReactNode } from 'react';
import { Flex } from '@/ui';

type Props = {
  children: ReactNode;
};

export const CardList = ({ children }: Props): JSX.Element => {
  return (
    <Flex direction="column" gap="6" pb="8">
      {children}
    </Flex>
  );
};
