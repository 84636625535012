import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useCurrentUserContext } from '@/contexts';
import { Confetti } from '@/pages/Schedule/components';
import { MotionFlex, Button, Flex, Heading, Icon, Image, Text } from '@/ui';
import mascot from './assets/mascot.png';
import { Cta } from './Cta';
import { Footer } from './Footer';
import { SocialShare } from './SocialShare';

type Props = {
  email: string;
  name: string;
  onDismiss?: () => void;
};

export const Success = ({ email, name, onDismiss }: Props) => {
  const { currentUser } = useCurrentUserContext();

  return (
    <>
      <Confetti />

      <MotionFlex
        bgColor="white"
        bgImage="linear-gradient(180deg, #E0E7F9, #F4E9F5)"
        bottom="0"
        direction="column"
        flex="1"
        fontWeight="medium"
        h="100vh"
        initial={{ top: '100vh', opacity: 0 }}
        justify="space-between"
        left="0"
        mt={{ base: 0 }}
        overflow="auto"
        p="10"
        position="fixed"
        pt="2"
        right="0"
        textAlign="center"
        zIndex="1500"
        animate={{
          top: 0,
          opacity: 1,
        }}
      >
        <Flex
          align="center"
          direction="column"
          justify={{ base: 'flex-start', md: 'center' }}
          maxW={{ md: 'container.xl' }}
          mx="auto"
          pos="relative"
          px="4"
          py="0"
          w="100%"
          zIndex="1"
        >
          {onDismiss && (
            <Button
              alignSelf="flex-start"
              borderRadius="3xl"
              colorScheme="dark"
              leftIcon={<Icon as={BsArrowLeft} />}
              variant="ghost"
              onClick={onDismiss}
            >
              Back to schedule
            </Button>
          )}
          <Image alt="" maxW="250px" mb="-4" src={mascot} w="100%" />
          <Heading
            color="black"
            fontSize="46px"
            fontWeight="extrabold"
            lineHeight="1"
            mb="8"
          >
            Nicely done!
          </Heading>

          {currentUser ? (
            <>
              <Text
                fontSize={{ base: 'md', sm: '18px' }}
                lineHeight="1.4"
                maxW="500px"
                mb="6"
                px={{ md: 5 }}
              >
                We&apos;re on a mission to save people time (and clicks). If you
                enjoyed this, consider telling a friend!
              </Text>

              <SocialShare />

              <Button as={Link} colorScheme="dark" to="/" variant="ghost">
                Return to Dashboard
              </Button>
            </>
          ) : (
            <Flex direction="column" my="8" w={{ base: '100%', md: '500px' }}>
              <Cta email={email} name={name} />
            </Flex>
          )}
        </Flex>

        <Footer />
      </MotionFlex>
    </>
  );
};
