import type { DecoratedInstance } from '@/pages/Schedule/types';
import { useAvailabilityResponses } from '@/pages/Schedule/views/ConflictView/hooks/useAvailabilityResponses';
import { hasResponses } from '@/pages/Schedule/views/ConflictView/lib/instance';
import { Button, Flex, Icon, Tooltip } from '@/ui';

type Props = {
  instance: DecoratedInstance;
  onEdit: () => void;
  onDelete: () => void;
};

export const AuthorControls = ({ instance, onEdit, onDelete }: Props) => {
  const { data: responses } = useAvailabilityResponses();
  const isEditDisabled = responses && hasResponses(instance, responses);

  return (
    <Flex align="center" gap="2" justify="flex-start" ml={{ base: 10, md: 0 }}>
      <Tooltip
        isDisabled={!isEditDisabled}
        label="This item has responses and cannot be changed"
      >
        <Button
          colorScheme="dark"
          isDisabled={isEditDisabled}
          leftIcon={<Icon icon="Pencil" />}
          size="sm"
          variant="shadow"
          onClick={onEdit}
        >
          Edit
        </Button>
      </Tooltip>
      <Button
        colorScheme="dark"
        leftIcon={<Icon icon="Trash" />}
        size="sm"
        variant="shadow"
        onClick={onDelete}
      >
        Delete
      </Button>
    </Flex>
  );
};
