import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AnimateChangeInHeight } from '@/components/AnimateChangeInHeight';
import { useCurrentUserContext } from '@/contexts';
import { useAvailabilityContext } from '@/pages/Schedule/views/ConflictView/context/AvailabilityContext';
import { useAvailabilitySubmit } from '@/pages/Schedule/views/ConflictView/hooks/useAvailabilitySubmit';
import { isEntryAvailabilityInput } from '@/pages/Schedule/views/ConflictView/lib/summary';
import {
  useToast,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@/ui';
import { isValidEmail } from '@/utils/validation';
import { ControlBar } from '../ControlBar';
import { ConflictSummary } from './components/ConflictSummary';
import { SubmitControls } from './components/SubmitControls';
import { Success } from './components/Success';
import { SummaryCard } from './components/SummaryCard';

type Responder = {
  name: string;
  email: string;
};

export const SubmissionView = () => {
  const toast = useToast();
  const { currentUser } = useCurrentUserContext();
  const { items } = useAvailabilityContext();
  const { isPending: isSubmitting, respond } = useAvailabilitySubmit();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<Responder>({
    defaultValues: {
      email: currentUser?.email || '',
      name: currentUser?.name || '',
    },
  });

  const [hasSaved, setSaved] = useState(false);
  const submitterName = watch('name');
  const email = watch('email');

  const handleRespond = (responder: Responder) => {
    respond(
      {
        ...responder,
        items: items.filter(isEntryAvailabilityInput),
      },
      {
        onSuccess: () => setSaved(true),
        onError: () =>
          toast.error(
            'We were unable to save your response, please try again.'
          ),
      }
    );
  };

  return (
    <Box as="form" noValidate onSubmit={handleSubmit(handleRespond)}>
      <Flex
        direction="column"
        gap="6"
        mb={{ base: 20, md: 0 }}
        mx="auto"
        pb="8"
        w="100%"
      >
        <ConflictSummary items={items} />

        <SummaryCard title="Who are you filling this out for?">
          <AnimateChangeInHeight>
            <Flex as="fieldset" direction="column" disabled={hasSaved} gap="2">
              <FormControl isInvalid={Boolean(errors.name)}>
                <FormLabel>Name</FormLabel>
                <Input
                  autoFocus={!submitterName}
                  type="text"
                  {...register('name', { required: true })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.email)}>
                <FormLabel>Email</FormLabel>
                <Input
                  autoComplete="email"
                  type="email"
                  {...register('email', {
                    required: true,
                    validate: {
                      email: (value) =>
                        isValidEmail(value) || 'Enter a valid email',
                    },
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
          </AnimateChangeInHeight>
        </SummaryCard>
      </Flex>

      <ControlBar>
        <SubmitControls
          isSubmitting={isSubmitting}
          onClick={handleSubmit(handleRespond)}
        />
      </ControlBar>

      {hasSaved && <Success email={email} name={submitterName} />}
    </Box>
  );
};
