import type { ReactNode } from 'react';
import { List as ChakraList } from '@/ui';

type Props = {
  children: ReactNode;
};

export const List = ({ children }: Props) => {
  return (
    <ChakraList display="flex" flexDir="column" gap="2">
      {children}
    </ChakraList>
  );
};
