import { AvailabilityType } from '@/types/gql.generated';
import { Card, CardBody, CardHeader } from '@/ui';
import { EntryDetails } from '../../../EntryDetails';
import type { AvailabilityInstanceGroup } from '../../utils';
import { GroupHeading } from '../GroupHeading';
import { GroupList } from '../GroupList';
import { ResponseContainer } from '../ResponseContainer';
import { InstanceConflict } from './InstanceConflict';

type Props = {
  group: AvailabilityInstanceGroup;
};

export const InstanceCard = ({ group }: Props): JSX.Element => {
  const fullConflicts = group.items.filter(
    (item) => item.status === AvailabilityType.Full
  );
  const partialConflicts = group.items.filter(
    (item) => item.status === AvailabilityType.Partial
  );

  return (
    <Card as="article" key={group.id}>
      <CardHeader bg="#fbfbfd" borderBottom="solid 1px rgba(0, 0, 0, 0.10)">
        <EntryDetails entry={group.entry} instance={group.instance} />
      </CardHeader>
      <CardBody>
        {group.items.length === 0 ? (
          <GroupHeading status={null} title="No conflicts" />
        ) : (
          <ResponseContainer>
            <GroupList>
              <GroupHeading
                status={AvailabilityType.Full}
                title={`Full conflicts (${fullConflicts.length})`}
              />
              {fullConflicts.map((item) => (
                <InstanceConflict item={item} key={item.id} />
              ))}
            </GroupList>
            <GroupList>
              <GroupHeading
                status={AvailabilityType.Partial}
                title={`Partial conflicts (${partialConflicts.length})`}
              />
              {partialConflicts.map((item) => (
                <InstanceConflict item={item} key={item.id} />
              ))}
            </GroupList>
          </ResponseContainer>
        )}
      </CardBody>
    </Card>
  );
};
