import {
  toDateFormat,
  toTimeFormat,
} from '@/pages/Schedule/components/ItemCard/utils';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import {
  AvailabilityType,
  type EntryAvailabilityInput,
} from '@/types/gql.generated';
import {
  areDatesSame,
  areTimesInSameMeridian,
  isMultiDay,
} from '@/utils/dates';
import type { AvailabilityStateItem } from '../types';
import { getInstanceDate } from './instance';

const isEntryAvailabilityType = (
  item: AvailabilityStateItem,
  type: AvailabilityType
): item is EntryAvailabilityInput => {
  return item.status === type;
};

export const getItems = (
  items: AvailabilityStateItem[],
  type: AvailabilityType
): EntryAvailabilityInput[] => {
  return items.filter((item) =>
    isEntryAvailabilityType(item, type)
  ) as EntryAvailabilityInput[];
};

export const formatSummaryText = (instance: string): string => {
  return toDateFormat(getInstanceDate(instance));
};

export const isEntryAvailabilityInput = (
  item: AvailabilityStateItem
): item is EntryAvailabilityInput => {
  return Boolean(item.status);
};

export const getSummaryShort = (items: AvailabilityStateItem[]): string => {
  const partialCount = getItems(items, AvailabilityType.Partial).length;
  const fullCount = getItems(items, AvailabilityType.Full).length;

  if (partialCount === 0 && fullCount === 0) {
    return 'No conflicts';
  }

  const partialSummary = partialCount > 0 ? `${partialCount} Partial` : null;
  const fullSummary = fullCount > 0 ? `${fullCount} Full` : null;

  return [fullSummary, partialSummary]
    .filter((text) => Boolean(text))
    .join(', ');
};

export const needsConsolidatedSummary = (entry: DecoratedEntry): boolean => {
  const {
    startDate: entryStartDate,
    endDate: entryEndDate,
    isOnDay,
  } = entry.recurrences[0];

  // defer to our common summarization util for multi-day and recurring items
  return isMultiDay(entryStartDate, entryEndDate) || isOnDay;
};

export const formatTime = (
  entry: DecoratedEntry,
  instance: DecoratedInstance
): string | null => {
  const recurrence = instance ? instance : entry.recurrences[0];
  const { startDate, endDate, isOnDay } = recurrence;

  // defer to our common summarization util for multi-day and recurring items
  if (isMultiDay(startDate, endDate) || isOnDay) {
    // formatTime is not the right tool for this case, we should use
    // summarizeTime from item card instead
    return null;
  }

  if (isOnDay) {
    return null;
  }

  if (areDatesSame(startDate, endDate)) {
    return toTimeFormat(startDate);
  }

  if (areTimesInSameMeridian(startDate, endDate)) {
    return `${toTimeFormat(startDate, false)} - ${toTimeFormat(endDate)}`;
  }

  return `${toTimeFormat(startDate)} to ${toTimeFormat(endDate)}`;
};
