import { config } from '@/config';

export const getLink = (source: string): string => {
  const query = new URLSearchParams({
    utm_source: source,
    utm_medium: 'social',
    utm_campaign: 'conflict-share',
  });

  return `${config.marketingUrl}?${query}`;
};

const FB_PARAMS = new URLSearchParams({ u: getLink('facebook') });
export const FACEBOOK_LINK = `https://www.facebook.com/sharer/sharer.php?${FB_PARAMS}`;

const TW_PARAMS = new URLSearchParams({
  text: `📆 @agendahero just helped me share my schedule conflicts with my group. No more back and forth over email 🙌 #TimeSaver ${getLink(
    'twitter'
  )}`,
});
export const TWITTER_LINK = `https://twitter.com/intent/tweet?${TW_PARAMS}`;

const LI_PARAMS = new URLSearchParams({
  url: getLink('linkedin'),
});
export const LINKEDIN_LINK = `https://www.linkedin.com/sharing/share-offsite/?${LI_PARAMS}`;
