import type { ReactNode } from 'react';
import { StackDivider, VStack } from '@/ui';

type Props = {
  children: ReactNode;
};

export const ResponseContainer = ({ children }: Props): JSX.Element => {
  return (
    <VStack align="flex-start" divider={<StackDivider />} spacing="5" w="100%">
      {children}
    </VStack>
  );
};
