import { PiShareFat } from 'react-icons/pi';
import { AnimateChangeInHeight } from '@/components/AnimateChangeInHeight';
import { useOpenShareModal } from '@/pages/Schedule/components/ShareModal/store';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  Alert,
  AlertIcon,
  Button,
  type ButtonProps,
  Flex,
  Heading,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@/ui';
import { CopyLinkButton } from './CopyLinkButton';
import { getShareLink } from './utils';

export const SendControl = (props: ButtonProps) => {
  const { schedule } = useScheduleContext();
  const { openShareModal } = useOpenShareModal();
  const isPublic = schedule?.isPublic ?? false;

  return (
    <Popover
      modifiers={[{ name: 'preventOverflow', options: { padding: 24 } }]}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              flexBasis={{ base: '1', md: 'auto' }}
              flexGrow={{ base: '1', md: '0' }}
              leftIcon={<PiShareFat />}
              size="sm"
              {...props}
            >
              Collect responses
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent borderRadius="lg" shadow="lg">
              <PopoverArrow />
              <PopoverBody px="5" py="4">
                <Heading as="h3" mb="2" size="sm">
                  🪄 Voila
                </Heading>

                <AnimateChangeInHeight mb="4">
                  {!isPublic && (
                    <Alert status="info" title="Heads up">
                      <AlertIcon />
                      <Text fontSize="sm">
                        This schedule is only accessible to collaborators. To
                        invite more collaborators or make it viewable, update
                        the schedule&apos;s{' '}
                        <Button variant="link" onClick={() => openShareModal()}>
                          sharing settings
                        </Button>
                        .
                      </Text>
                    </Alert>
                  )}
                </AnimateChangeInHeight>

                <Flex direction="column" gap="4">
                  <Text>
                    A beautiful form has been created from the information you
                    provided.{' '}
                    <Link href={getShareLink()} target="_blank">
                      See preview
                    </Link>
                  </Text>
                  <Text>
                    Copy and share the link below to collect conflicts.
                  </Text>

                  <CopyLinkButton
                    alignSelf="center"
                    variant="solid"
                    onClick={onClose}
                  >
                    Copy share link
                  </CopyLinkButton>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
