import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  GoogleSignInButton,
  PasswordInput,
  useAuthenticate,
} from '@/components/auth';
import { Terms } from '@/components/auth/Terms';
import { AuthAction } from '@/types/gql.generated';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@/ui';
import { getErrorMessage } from '@/utils/errors';

type Props = {
  email: string;
  name: string;
};

type FormValues = {
  email: string;
  name: string;
  password: string;
};

export const Register = ({ email, name }: Props): JSX.Element => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const {
    authenticate,
    isLoading: isAuthenticating,
    error: authError,
  } = useAuthenticate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email,
      name,
      password: '',
    },
  });

  const signup = (data: FormValues): void => {
    authenticate(AuthAction.Register, data, {
      // setTimeout is needed here to allow currentUserContext to refresh with
      // new user. Otherwise Dashboard will kick back out to /auth
      onSuccess: () => setTimeout(() => navigate('/'), 0),
    });
  };

  return (
    <Flex direction="column" gap="3">
      <Text
        fontSize={{ base: 'md', sm: '18px' }}
        lineHeight="1.4"
        maxW="500px"
        mb="6"
        px={{ md: 5 }}
      >
        We&apos;re on a mission to save people time (and clicks). Sign up (as{' '}
        {email}) to create your own.
      </Text>
      <Flex
        as="form"
        direction="column"
        gap="3"
        onSubmit={handleSubmit(signup)}
      >
        {authError && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{getErrorMessage(authError)}</AlertTitle>
          </Alert>
        )}

        <input autoComplete="username" type="hidden" value={email} />
        <FormControl isInvalid={!!errors.password} variant="floating">
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <PasswordInput
                autoComplete="new-password"
                autoFocus
                bg="white"
                id="password"
                placeholder={t('password')}
                {...field}
              />
            )}
            rules={{
              validate: {
                password: (value) => {
                  if (!value.trim().length) {
                    return t('validation.required');
                  }
                  if (value.length < 8) {
                    return t('validation.password_too_short');
                  }
                },
              },
            }}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <Button isLoading={isAuthenticating} type="submit">
          {t('create_account')}
        </Button>
      </Flex>

      <Flex align="center" gap="3" my="4">
        <Divider borderColor="text" />
        <Text>{t('or')}</Text>
        <Divider borderColor="text" />
      </Flex>

      <GoogleSignInButton redirectTo="/" type="register" />

      <Terms mt="1" />
    </Flex>
  );
};
