import { Container, type BoxProps, Box } from '@/ui';
import { MAX_WIDTH, PADDING_X } from '../../../lib/dimensions';

export const ControlBar = ({ children, ...boxProps }: BoxProps) => {
  return (
    <Box
      bg="white"
      bottom="0"
      left="0"
      pos="fixed"
      py="4"
      right="0"
      shadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1),0 -2px 4px -1px rgba(0, 0, 0, 0.06)"
      zIndex="1"
      {...boxProps}
    >
      <Container variant="max">
        <Container maxW={MAX_WIDTH} px={PADDING_X}>
          {children}
        </Container>
      </Container>
    </Box>
  );
};
