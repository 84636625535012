import type { DecoratedInstance } from '@/pages/Schedule/types';
import type { AvailabilityType } from '@/types/gql.generated';
import { createContext } from '@/utils';
import type { Availability, AvailabilityStateItem } from '../../types';

type AvailabilityContextType = {
  items: AvailabilityStateItem[];
  setAvailability: (
    instance: DecoratedInstance,
    availability: Partial<Availability>
  ) => void;
  clearAvailability: (instance: DecoratedInstance) => void;
  getConflictForInstance: (
    instance: DecoratedInstance
  ) => AvailabilityStateItem | undefined;
  hasConflict: (
    instance: DecoratedInstance,
    status: AvailabilityType
  ) => boolean;
};

export const AvailabilityContext = createContext<AvailabilityContextType>(
  {
    items: [],
    setAvailability: () => {},
    clearAvailability: () => {},
    getConflictForInstance: () => undefined,
    hasConflict: () => false,
  },
  'AvailabilityContext'
);
