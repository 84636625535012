import { ScheduleTitle } from '@/pages/Schedule/components/ScheduleHero/ScheduleTitle';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { Flex } from '@/ui';
import { Mode, useExecutionContext } from '../context/ExecutionContext';
import { Navigation } from './Navigation';

export const Header = () => {
  const { mode } = useExecutionContext();
  const isAuthoring = mode === Mode.Author;
  const { hasSchedulePermission } = useSchedulePermissions();
  const isOrganizer = hasSchedulePermission('ENTRY_CREATE');

  return (
    <Flex
      align="flex-start"
      direction="column"
      gap="4"
      m="auto"
      maxW={{ lg: 'container.lg' }}
      w="100%"
    >
      <ScheduleTitle
        defaultTitle="Conflict Calendar"
        ml="-3"
        w="calc(100% + (var(--chakra-space-3) * 2))"
      />

      {isAuthoring && isOrganizer && <Navigation />}
    </Flex>
  );
};
