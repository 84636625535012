import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  Badge,
  Box,
  Tab as ChakraTab,
  Flex,
  TabIndicator,
  TabList,
  type TabProps,
  Tabs,
} from '@/ui';
import { useActiveMode, MODES } from '../hooks/useActiveMode';
import { useAvailabilityResponses } from '../hooks/useAvailabilityResponses';
import { AuthorControls } from './AuthorView/components/Controls';

const Tab = (props: TabProps) => (
  <ChakraTab
    _selected={{ color: 'chakra-body-text' }}
    color="gray.600"
    fontWeight="bold"
    {...props}
  />
);

export const Navigation = () => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { activeIndex, setMode } = useActiveMode();
  const { data: responses } = useAvailabilityResponses();

  return (
    <Tabs
      index={activeIndex}
      variant="unstyled"
      w="100%"
      onChange={(index) => setMode(MODES[index])}
    >
      <Flex align="center" justify="space-between">
        <Box flexGrow="1" pos="relative">
          <TabList gap="3">
            <Tab px="0">Schedule</Tab>
            <Tab px="0">
              Responses
              <Badge
                bg="customgray.dark"
                borderRadius="3px"
                color="white"
                ml="1"
              >
                {responses?.length != null ? responses.length : '…'}
              </Badge>
            </Tab>
          </TabList>
          <TabIndicator bg="brand.500" borderRadius="1px" h="2px" mt="-1.5px" />
        </Box>
        {!isMobileBreakpoint && <AuthorControls />}
      </Flex>
    </Tabs>
  );
};
