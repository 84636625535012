import { Conflict } from '@/pages/Schedule/views/ConflictView/components/Conflict';
import {
  formatSummaryText,
  getItems,
} from '@/pages/Schedule/views/ConflictView/lib/summary';
import type { AvailabilityStateItem } from '@/pages/Schedule/views/ConflictView/types';
import { AvailabilityType } from '@/types/gql.generated';
import { Flex, ListItem, Text } from '@/ui';
import { SummaryCard } from '../SummaryCard';
import { List } from './List';
import { Title } from './Title';

type Props = {
  items: AvailabilityStateItem[];
};

export const ConflictSummary = ({ items }: Props) => {
  const fullItems = getItems(items, AvailabilityType.Full);
  const partialItems = getItems(items, AvailabilityType.Partial);

  return (
    <SummaryCard title="Summary">
      {items.length === 0 ? (
        <Text>🎉 No conflicts</Text>
      ) : (
        <Flex direction="column" gap="3">
          {fullItems.length > 0 && (
            <>
              <Title>Full conflicts</Title>
              <List>
                {fullItems.map((item) => {
                  return (
                    <ListItem key={`${item.entryId}:${item.instance}`}>
                      <Conflict
                        availability={{
                          ...item,
                          title: formatSummaryText(item.instance),
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}

          {partialItems.length > 0 && (
            <>
              <Title>Partial conflicts</Title>
              <List>
                {partialItems.map((item) => {
                  return (
                    <ListItem key={`${item.entryId}:${item.instance}`}>
                      <Conflict
                        availability={{
                          ...item,
                          title: formatSummaryText(item.instance),
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </Flex>
      )}
    </SummaryCard>
  );
};
