import { Button, Flex } from '@/ui';
import { useAvailabilityContext } from '../../../context/AvailabilityContext/useAvailabilityContext';
import { Mode, useExecutionContext } from '../../../context/ExecutionContext';
import { AuthorControls } from '../../AuthorView/components/Controls';
import { InlineSummary } from '../../Conflict/InlineSummary';

export const Controls = () => {
  const { mode, setMode } = useExecutionContext();
  const { items } = useAvailabilityContext();

  if (mode === Mode.Author) {
    return <AuthorControls />;
  }

  return (
    <>
      <Flex
        align="center"
        gap="4"
        justify={{ base: 'space-between', md: 'flex-end' }}
      >
        <InlineSummary items={items} />

        <Button onClick={() => setMode(Mode.Submit)}>Submit response</Button>
      </Flex>
    </>
  );
};
