import { AnimatePresence, motion } from 'framer-motion';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import type { ViewProps } from '@/pages/Schedule/views/types';
import { TabPanel, TabPanels, Tabs } from '@/ui';
import { useActiveMode } from '../../hooks/useActiveMode';
import { ResponsesView } from '../ResponsesView';
import { ScheduleView } from '../ScheduleView';

const MotionPanels = motion(TabPanels);

export const AuthorView = (props: ViewProps) => {
  const { activeIndex } = useActiveMode();
  const { hasSchedulePermission } = useSchedulePermissions();
  const isOrganizer = hasSchedulePermission('ENTRY_CREATE');

  return (
    <Tabs index={activeIndex} isLazy variant="unstyled">
      <AnimatePresence mode="popLayout">
        <MotionPanels
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          key={activeIndex}
          transition={{ duration: 0.3 }}
        >
          <TabPanel p="0">
            <ScheduleView {...props} />
          </TabPanel>
          <TabPanel p="0">{isOrganizer && <ResponsesView />}</TabPanel>
        </MotionPanels>
      </AnimatePresence>
    </Tabs>
  );
};
