import { BsArrowLeft } from 'react-icons/bs';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Button, Flex, Icon } from '@/ui';
import { useAvailabilityContext } from '../../../../../context/AvailabilityContext';
import {
  Mode,
  useExecutionContext,
} from '../../../../../context/ExecutionContext';
import { InlineSummary } from '../../../../Conflict/InlineSummary';

type Props = {
  isSubmitting: boolean;
  onClick: () => void;
};

export const SubmitControls = ({ isSubmitting, onClick }: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { setMode } = useExecutionContext();
  const { items } = useAvailabilityContext();

  return (
    <Flex
      align="center"
      gap="6"
      justify="space-between"
      m="auto"
      maxW="container.md"
    >
      <Button
        borderRadius="3xl"
        colorScheme="dark"
        leftIcon={<Icon as={BsArrowLeft} />}
        variant="ghost"
        onClick={() => setMode(Mode.Respond)}
      >
        Edit conflicts
      </Button>

      <Flex
        align="center"
        gap="3"
        justify={{ base: 'space-between', md: 'flex-end' }}
      >
        {!isMobileBreakpoint && <InlineSummary items={items} />}

        <Button isLoading={isSubmitting} type="submit" onClick={onClick}>
          Submit response
        </Button>
      </Flex>
    </Flex>
  );
};
