import { DateTime } from 'luxon';
import type { DecoratedInstance } from '@/pages/Schedule/types';
import type { AvailabilityResponse } from '../types';

export const getDateString = (instance: string): string => {
  const dateString = instance.split('_').pop();
  if (!dateString) {
    // Should never hit this
    throw Error('Unrecognized entry instance');
  }
  return dateString;
};

export const getInstanceDate = (instance: string): DateTime => {
  return DateTime.fromISO(getDateString(instance));
};

export const hasResponses = (
  instance: DecoratedInstance,
  responses: AvailabilityResponse[]
): boolean => {
  return responses.some((response) => {
    return response.items.some((item) => {
      return (
        item.entry.id === instance.parentId &&
        item.instance === getDateString(instance.id)
      );
    });
  });
};
