import type { ReactNode } from 'react';
import { Card, CardBody, Flex, Heading } from '@/ui';

type Props = {
  title: ReactNode;
  children: ReactNode;
};

export const SummaryCard = ({ title, children }: Props) => {
  return (
    <Flex direction="column" gap="2">
      <Heading as="h3" fontWeight="bold" size="sm">
        {title}
      </Heading>

      <Card>
        <CardBody>{children}</CardBody>
      </Card>
    </Flex>
  );
};
