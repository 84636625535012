import { Route, Routes } from 'react-router-dom';
import { Flex } from '@/ui';
import type { ViewProps } from '../types';
import { AuthorView } from './components/AuthorView/AuthorView';
import { Header } from './components/Header';
import { ScheduleView as RespondView } from './components/ScheduleView';
import { AvailabilityContextProvider } from './context/AvailabilityContext/AvailabilityContextProvider';
import { ExecutionContextProvider } from './context/ExecutionContext/ExecutionContextProvider';
import { MAX_WIDTH, PADDING_X } from './lib/dimensions';

export default function ConflictView(props: ViewProps) {
  return (
    <ExecutionContextProvider>
      <AvailabilityContextProvider>
        <Flex
          bg="white"
          borderRadius={{ md: '2xl' }}
          direction="column"
          gap="6"
          maxW={MAX_WIDTH}
          mt={{ base: 0, md: 8, xl: 2 }}
          mx="auto"
          pb={{ base: '120px', md: '128px' }}
          pt={{ base: 0, md: '28px', lg: '48px' }}
          px={PADDING_X}
          w="100%"
        >
          <Header />

          <Routes>
            <Route element={<RespondView {...props} />} path="respond" />
            <Route element={<AuthorView {...props} />} index />
          </Routes>
        </Flex>
      </AvailabilityContextProvider>
    </ExecutionContextProvider>
  );
}
