import type { AvailabilityType } from '@/types/gql.generated';
import { Flex, Heading, Text } from '@/ui';
import type { Availability } from '../../types';
import { ConflictIcon } from './ConflictIcon';

type Props = {
  availability: Omit<Availability, 'status'> & {
    title: string;
    status: AvailabilityType | null;
  };
};

export const Conflict = ({ availability }: Props) => {
  return (
    <Flex align="flex-start" gap="2" justify="flex-start">
      <ConflictIcon
        position="relative"
        status={availability.status}
        top="-0.5"
      />
      <Flex align="flex-start" direction="column" justify="flex-start">
        <Heading as="h5" size="xs">
          {availability.title}
        </Heading>
        {availability.note && (
          <Text fontSize="sm" variant="mid">
            {availability.note}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
