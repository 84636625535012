import { Flex, Heading, Text } from '@/ui';
import type { InstanceItem } from '../../utils';

type Props = {
  item: InstanceItem;
};

export const InstanceConflict = ({ item }: Props): JSX.Element => {
  return (
    <Flex align="flex-start" gap="2" justify="flex-start">
      <Flex align="flex-start" direction="column" justify="flex-start">
        <Heading as="h5" color="dark.600" fontSize="sm" fontWeight="bold">
          {item.name}
        </Heading>
        {item.note && <Text fontSize="sm">&ldquo;{item.note}&rdquo;</Text>}
      </Flex>
    </Flex>
  );
};
