import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { Flex, Text, Heading } from '@/ui';
import { SendControl } from '../../SendControl';

export const EmptyState = () => {
  const { hasSchedulePermission } = useSchedulePermissions();

  return (
    <Flex align="center" color="customgray.mid" direction="column" py="10">
      <Heading
        as="h3"
        fontSize={{ base: '2xl', md: '4xl' }}
        fontWeight="400"
        textAlign="center"
      >
        No responses yet
      </Heading>

      {hasSchedulePermission('ENTRY_CREATE') && (
        <>
          <Text mt="1">
            Share the link to your conflict form to start collecting responses.
          </Text>

          <SendControl fontSize="md" mt="8" size="md" variant="secondary" />
        </>
      )}
    </Flex>
  );
};
