import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLink } from 'react-icons/hi';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useToast } from '@/ui';
import { Button, type ButtonProps } from '@/ui';
import { getShareLink } from './utils';

export const CopyLinkButton = ({ onClick, ...props }: ButtonProps) => {
  const { t } = useTranslation('schedule', {
    keyPrefix: 'copy_link',
  });
  const toast = useToast();
  const { trackEvent } = useAnalytics();

  const handleCopyClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    trackEvent('schedule:click copy-share-link');

    navigator.clipboard.writeText(getShareLink());
    toast.notify(t('copy_success'));

    onClick?.(event);
  };

  return (
    <Button
      colorScheme="dark"
      leftIcon={<HiOutlineLink />}
      variant="ghost"
      onClick={handleCopyClick}
      {...props}
    >
      {props.children || t('copy_button')}
    </Button>
  );
};
