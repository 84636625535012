import { DateTime } from 'luxon';
import type { EntriesMap } from '@/pages/Schedule/contexts';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import type { AvailabilityType } from '@/types/gql.generated';
import { getDateString } from '../../lib/instance';
import type { AvailabilityResponse } from '../../types';

export type InstanceItem = {
  id: string;
  name: string;
  submittedAt: DateTime;
  status: AvailabilityType;
  note?: string;
};

export type AvailabilityInstanceGroup = {
  id: string;
  entry: DecoratedEntry;
  instance: DecoratedInstance;
  items: InstanceItem[];
};

export type ResponseItem = {
  id: string;
  entry: DecoratedEntry;
  instance: DecoratedInstance;
  status: AvailabilityType;
  note: string | undefined;
};

export type AvailabilityResponseGroup = {
  id: string;
  name: string;
  submittedAt: DateTime;
  items: ResponseItem[];
};

const toInstanceId = (entryId: string, instanceId: string): string => {
  return [entryId, instanceId].join('_');
};

const isResponseItem = (item: ResponseItem | null): item is ResponseItem => {
  return Boolean(item);
};

export const groupByResponder = (
  entriesMap: EntriesMap,
  entryInstances: DecoratedInstance[],
  responses: AvailabilityResponse[]
): AvailabilityResponseGroup[] => {
  return responses
    .map((response) => {
      return {
        id: response.id,
        name: response.name,
        submittedAt: DateTime.fromISO(response.submittedAt),
        items: response.items
          .map<ResponseItem | null>((item) => {
            const entry = entriesMap[item.entry.id];
            if (!entry) {
              return null;
            }
            const instance = entryInstances.find((instance) => {
              return toInstanceId(entry.id, item.instance) === instance.id;
            });

            if (!instance) {
              return null;
            }

            return {
              id: item.id,
              status: item.status,
              note: item.note || undefined,
              entry,
              instance,
            };
          })
          .filter<ResponseItem>(isResponseItem)
          .sort((a, b) => {
            const aStartDate = a.entry.recurrences[0].startDate;
            const bStartDate = b.entry.recurrences[0].startDate;
            return aStartDate > bStartDate ? 1 : -1;
          }),
      };
    })
    .sort((a, b) => (a.submittedAt > b.submittedAt ? -1 : 1));
};

export const groupByDate = (
  entriesMap: EntriesMap,
  entryInstances: DecoratedInstance[],
  responses: AvailabilityResponse[]
): AvailabilityInstanceGroup[] => {
  const emptyGroups = entryInstances.reduce<
    Record<string, AvailabilityInstanceGroup>
  >((index, instance) => {
    const instanceDate = getDateString(instance.id);
    const entry = entriesMap[instance.parentId];
    if (!entry) {
      // Can happen when creating new entries during an existing schedule
      return index;
    }
    const groupId = toInstanceId(entry.id, instanceDate);
    index[groupId] = {
      id: groupId,
      items: [],
      entry,
      instance,
    };
    return index;
  }, {});

  const groups = responses.reduce<Record<string, AvailabilityInstanceGroup>>(
    (result, response) => {
      return response.items.reduce((result, item) => {
        const groupId = toInstanceId(item.entry.id, item.instance);
        if (!result[groupId]) {
          // Instances probably still loading
          return result;
        }

        result[groupId].items.push({
          id: item.id,
          status: item.status,
          name: response.name,
          submittedAt: DateTime.fromISO(response.submittedAt),
          note: item.note || undefined,
        });

        return result;
      }, result);
    },
    emptyGroups
  );

  return Object.values(groups)
    .map<AvailabilityInstanceGroup>((group) => {
      return {
        ...group,
        availability: group.items.sort((a, b) => {
          return a.submittedAt > b.submittedAt ? 1 : -1;
        }),
      };
    })
    .sort((a, b) => {
      const aStartDate = a.instance.startDate;
      const bStartDate = b.instance.startDate;
      return aStartDate > bStartDate ? 1 : -1;
    });
};
