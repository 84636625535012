import { useSort, type Sort, SORT } from '../hooks/useSort';
import { Switch } from './Switch';

const labels: Record<Sort, string> = {
  date: 'By Date',
  responder: 'By Person',
};

export const SortSelect = () => {
  const { activeSort, setSort } = useSort();

  return (
    <Switch
      value={activeSort}
      width="fit-content"
      options={SORT.map((value) => ({
        label: labels[value],
        value,
      }))}
      onChange={(value) => setSort(value)}
    />
  );
};
