import type { AvailabilityType } from '@/types/gql.generated';
import { Heading, Text } from '@/ui';
import { ConflictIcon } from '../../Conflict/ConflictIcon';

type Props = {
  status: AvailabilityType | null;
  title: string;
};

export const GroupHeading = ({ status, title }: Props) => (
  <Heading
    alignItems="center"
    as="h4"
    color="dark.500"
    display="flex"
    fontSize="sm"
    fontWeight="bold"
    gap="1"
  >
    <ConflictIcon boxSize="18px" status={status} />
    <Text as="span">{title}</Text>
  </Heading>
);
