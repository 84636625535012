import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib/gql-client';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { createAvailabilityResponsesQueryKey } from '@/utils/queryKeys';
import type { AvailabilityResponse } from '../types';
import type {
  GetAvailabilityResponsesQuery,
  GetAvailabilityResponsesQueryVariables,
} from './useAvailabilityResponses.generated';

const query = gql`
  query GetAvailabilityResponses($scheduleId: ID!) {
    getAvailabilityResponses(scheduleId: $scheduleId) {
      id
      submittedAt
      name
      email
      items {
        id
        instance
        status
        note
        entry {
          id
          title
          timeZone
        }
      }
    }
  }
`;

const selector = (
  data: GetAvailabilityResponsesQuery
): AvailabilityResponse[] => {
  return data.getAvailabilityResponses;
};

export const useAvailabilityResponses = () => {
  const { scheduleId } = useScheduleContext();

  return useQuery({
    queryKey: createAvailabilityResponsesQueryKey(scheduleId),
    select: selector,
    queryFn: () => {
      return gqlClient.request<
        GetAvailabilityResponsesQuery,
        GetAvailabilityResponsesQueryVariables
      >(query, { scheduleId });
    },
  });
};
