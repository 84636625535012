import { useAnalytics } from '@/hooks/useAnalytics';
import { Flex, Image, Link, Text } from '@/ui';
import facebook from './assets/logo-facebook.png';
import linkedin from './assets/logo-linkedin.png';
import x from './assets/logo-x.png';
import { Card } from './Card';
import { NativeShare } from './Native';
import { FACEBOOK_LINK, LINKEDIN_LINK, TWITTER_LINK } from './shareLib';

export const SocialShare = () => {
  const { trackEvent } = useAnalytics();

  return (
    <Card
      bg="rgba(255, 255, 255, 0.5)"
      direction="column"
      gap="4"
      justify="center"
      maxW="500px"
      mb="5"
      pb="7"
      pt="6"
    >
      <Text
        fontSize={{ base: 'md', sm: '18px' }}
        fontWeight="black"
        lineHeight="1.4"
        px={{ md: 5 }}
      >
        Spread the word!
      </Text>

      <Flex gap="3" justify="center">
        <NativeShare />
        <Link
          href={TWITTER_LINK}
          target="_blank"
          onClick={() => {
            trackEvent('conflict-schedule-success:click share', {
              channel: 'twitter',
            });
          }}
        >
          <Image alt="X" boxSize="40px" src={x} />
        </Link>
        <Link
          href={FACEBOOK_LINK}
          target="_blank"
          onClick={() => {
            trackEvent('conflict-schedule-success:click share', {
              channel: 'facebook',
            });
          }}
        >
          <Image alt="Facebook" boxSize="40px" src={facebook} />
        </Link>
        <Link
          href={LINKEDIN_LINK}
          target="_blank"
          onClick={() => {
            trackEvent('conflict-schedule-success:click share', {
              channel: 'linkedin',
            });
          }}
        >
          <Image alt="LinkedIn" boxSize="40px" src={linkedin} />
        </Link>
      </Flex>
    </Card>
  );
};
