import type { DecoratedInstance } from '@/pages/Schedule/types';
import { useAvailabilityContext } from '@/pages/Schedule/views/ConflictView/context/AvailabilityContext/useAvailabilityContext';
import {
  Mode,
  useExecutionContext,
} from '@/pages/Schedule/views/ConflictView/context/ExecutionContext';
import { useAvailabilitySubmit } from '@/pages/Schedule/views/ConflictView/hooks/useAvailabilitySubmit';
import { AvailabilityType } from '@/types/gql.generated';
import { Button, type ButtonProps } from '@/ui';

type Props = {
  availabilityType: AvailabilityType;
  instance: DecoratedInstance;
  isAlternate: boolean;
  bg: ButtonProps['bg'];
};

const LABELS: Record<AvailabilityType, string> = {
  [AvailabilityType.Full]: 'Full Conflict',
  [AvailabilityType.Partial]: 'Partial Conflict',
};

export const ConflictButton = ({
  instance,
  availabilityType,
  isAlternate,
  bg,
}: Props) => {
  const { isPending: isSubmitting } = useAvailabilitySubmit();
  const { setAvailability, clearAvailability, hasConflict } =
    useAvailabilityContext();
  const { mode } = useExecutionContext();
  const isAuthoring = mode === Mode.Author;

  return (
    <Button
      colorScheme={isAlternate ? 'gray' : 'dark'}
      flexGrow={{ base: 1, lg: 0 }}
      isDisabled={isSubmitting}
      pointerEvents={isAuthoring ? 'none' : 'auto'}
      size="sm"
      variant="shadow"
      {...(hasConflict(instance, availabilityType)
        ? {
            bg,
            _hover: { bg },
            _focus: { bg },
            color: 'white',
            onClick: () => clearAvailability(instance),
          }
        : {
            onClick: () => {
              setAvailability(instance, {
                status: availabilityType,
              });
            },
          })}
    >
      {LABELS[availabilityType]}
    </Button>
  );
};
