import { useEffect, useState } from 'react';
import { GoogleSignInButton } from '@/components/auth';
import { useAuthAction } from '@/components/auth/hooks/useAuthAction';
import { AuthAction } from '@/types/gql.generated';
import { Flex, Text } from '@/ui';
import { PasswordLogin } from './PasswordLogin';
import { Register } from './Register';

type Props = {
  email: string;
  name: string;
};

export const Cta = ({ email, name }: Props): JSX.Element | null => {
  const { getAuthAction, isLoading: isLoadingAuthAction } = useAuthAction();
  const [authAction, setAuthAction] = useState<null | AuthAction>(null);

  useEffect(() => {
    (async () => {
      const authAction = await getAuthAction(email);
      setAuthAction(authAction);
    })();
  }, [email, getAuthAction]);

  if (!authAction || isLoadingAuthAction) {
    return null;
  }

  if (authAction === AuthAction.Register) {
    return <Register email={email} name={name} />;
  }
  if (authAction === AuthAction.LoginPassword) {
    return <PasswordLogin email={email} name={name} />;
  }
  if (authAction === AuthAction.LoginGoogle) {
    return (
      <Flex direction="column" gap="3">
        <Text
          fontSize={{ base: 'md', sm: '18px' }}
          lineHeight="1.4"
          maxW="500px"
          mb="6"
          px={{ md: 5 }}
        >
          We&apos;re on a mission to save people time (and clicks). Sign in to
          access your account.
        </Text>
        <GoogleSignInButton redirectTo="/" type="login" />
      </Flex>
    );
  }

  // never
  return null;
};
