import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { Flex } from '@/ui';
import {
  Mode,
  useExecutionContext,
} from '../../../../../context/ExecutionContext';
import { EntryDetails } from '../../../../EntryDetails';
import { AuthorControls } from './AuthorControls';
import { RespondControls } from './RespondControls';

type Props = {
  entry: DecoratedEntry;
  instance: DecoratedInstance;
  onEdit: (instance: DecoratedInstance) => void;
  onDelete: (entry: DecoratedEntry, instance: DecoratedInstance) => void;
};

export const EntryItem = ({ entry, instance, onEdit, onDelete }: Props) => {
  const { mode } = useExecutionContext();

  return (
    <Flex
      align={{ base: 'stretch', md: 'center' }}
      direction={{ base: 'column', md: 'row' }}
      gap={{ base: 0, md: '6' }}
      justify="space-between"
    >
      <EntryDetails entry={entry} instance={instance} py="3" />

      {mode === Mode.Author ? (
        <AuthorControls
          instance={instance}
          onDelete={() => onDelete(entry, instance)}
          onEdit={() => onEdit(instance)}
        />
      ) : (
        <RespondControls instance={instance} />
      )}
    </Flex>
  );
};
