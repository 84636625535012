import { toDateFormat } from '@/pages/Schedule/components/ItemCard/utils';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { getDateString } from '@/pages/Schedule/views/ConflictView/lib/instance';
import { formatTime } from '@/pages/Schedule/views/ConflictView/lib/summary';
import { Flex, Heading, Text } from '@/ui';
import { createEntryDate } from '@/utils/dates';
import type { ResponseItem } from '../../utils';

type Props = {
  item: ResponseItem;
};

export const ResponseConflict = ({ item }: Props): JSX.Element => {
  const { timeZone: scheduleTimeZone } = useScheduleContext();
  const isAllDay = item.entry.recurrences[0].isOnDay;

  const itemDate = createEntryDate(
    getDateString(item.instance.id),
    item.entry.timeZone,
    scheduleTimeZone,
    isAllDay
  );

  return (
    <Flex align="flex-start" gap="2" justify="flex-start">
      <Flex align="flex-start" direction="column" justify="flex-start">
        <Heading
          as="h5"
          color="dark.600"
          fontWeight="bold"
          lineHeight="20px"
          size="xs"
        >
          {item.entry.title || 'Untitled item'}
        </Heading>
        <Text color="dark.600" fontSize="sm">
          {toDateFormat(itemDate)}
        </Text>
        {!isAllDay && (
          <Text color="dark.600" fontSize="sm">
            {formatTime(item.entry, item.instance)}
          </Text>
        )}
        {item.note && (
          <Text color="dark.600" fontSize="sm">
            &ldquo;{item.note}&rdquo;
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
