import { PiShareFat } from 'react-icons/pi';
import { useToast, Icon, IconButton } from '@/ui';
import { getLink } from './shareLib';

export const NativeShare = () => {
  const toast = useToast();

  const handleClick = async (): Promise<void> => {
    if (typeof navigator.share === 'function') {
      try {
        await navigator.share({
          url: getLink('native'),
          text: '🪄 Presto! Agenda Hero just turned a paragraph of text into a beautifully structured calendar event using AI - magic! 📆 Saved me time & clicks',
        });
      } catch (e) {
        // user may have abandoned share
      }
    } else {
      // Native share menu not supported, fall back to clipboard
      try {
        await navigator.clipboard.writeText(getLink('copy'));

        toast.notify({
          description: 'Thanks for sharing!',
          title: 'Copied link to clipboard',
        });
      } catch (err) {
        toast.error({
          description: 'We were unable to copy the link to the clipboard',
        });
      }
    }
  };

  return (
    <IconButton
      aria-label="Share"
      bg="#716ADD"
      borderRadius="6px"
      color="white"
      h="40px"
      icon={<Icon as={PiShareFat} h="24px" w="24px" />}
      w="40px"
      _hover={{
        bg: '#716ADD',
      }}
      onClick={handleClick}
    />
  );
};
