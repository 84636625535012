import { AppLoader } from '@/components/AppLoader';
import { Flex } from '@/ui';
import { useAvailabilityResponses } from '../../hooks/useAvailabilityResponses';
import { EmptyState } from './components/EmptyState';
import { InstanceConflictList } from './components/InstanceConflictList';
import { ResponseConflictList } from './components/ResponseConflictList';
import { SortSelect } from './components/SortSelect';
import { useSort } from './hooks/useSort';

export const ResponsesView = () => {
  const { activeSort } = useSort();
  const { data: responses, isLoading } = useAvailabilityResponses();

  if (isLoading || !responses) {
    return <AppLoader />;
  }

  if (responses.length === 0) {
    return <EmptyState />;
  }

  return (
    <Flex direction="column" gap="4" pb="8">
      <SortSelect />

      {activeSort === 'responder' ? (
        <ResponseConflictList />
      ) : (
        <InstanceConflictList />
      )}
    </Flex>
  );
};
