import type { ReactNode } from 'react';
import { AvailabilityType } from '@/types/gql.generated';
import { Box, Flex, Text } from '@/ui';
import { Mode, useExecutionContext } from '../../context/ExecutionContext';
import { getItems } from '../../lib/summary';
import type { AvailabilityStateItem } from '../../types';
import { ConflictIcon } from './ConflictIcon';

type Props = {
  items: AvailabilityStateItem[];
};

type TagProps = {
  status: AvailabilityType;
  children: ReactNode;
};

const Tag = ({ status, children }: TagProps): JSX.Element => {
  return (
    <Flex align="center" display="inline-flex" gap="2">
      <ConflictIcon status={status} />
      <Box as="span">{children}</Box>
    </Flex>
  );
};

export const InlineSummary = ({ items }: Props): JSX.Element => {
  const { mode } = useExecutionContext();
  const partialCount = getItems(items, AvailabilityType.Partial).length;
  const fullCount = getItems(items, AvailabilityType.Full).length;

  const partialSummary = partialCount > 0 ? `${partialCount} Partial` : null;
  const fullSummary = fullCount > 0 ? `${fullCount} Full` : null;

  return (
    <Flex align="center" gap="3">
      {!fullSummary && !partialSummary && mode !== Mode.Submit && (
        <Text>No conflicts</Text>
      )}
      {fullSummary && <Tag status={AvailabilityType.Full}>{fullSummary}</Tag>}
      {partialSummary && (
        <Tag status={AvailabilityType.Partial}>{partialSummary}</Tag>
      )}
    </Flex>
  );
};
