import { AnimatePresence } from 'framer-motion';
import { AddContentButton } from '@/pages/Schedule/components/AddContentButton';
import { MotionBox, Button, Flex, Text } from '@/ui';
import { Mode, useActiveMode } from '../../../hooks/useActiveMode';
import { SendControl } from '../../SendControl';

const fade = {
  hide: { opacity: 0 },
  show: { opacity: 1 },
};

export const AuthorControls = () => {
  const { activeMode } = useActiveMode();

  return (
    <Flex
      align="center"
      gap="2"
      justify={{ base: 'stretch', md: 'flex-start' }}
    >
      <AnimatePresence>
        {activeMode === Mode.Schedule && (
          <MotionBox
            animate="show"
            exit="hide"
            flexBasis={{ base: '1', md: 'auto' }}
            flexGrow={{ base: '1', md: '0' }}
            initial="hide"
            variants={fade}
          >
            <AddContentButton
              buttonComponent={
                <Button
                  colorScheme="dark"
                  size="sm"
                  variant="shadow"
                  w={{ base: '100%', md: 'auto' }}
                  leftIcon={
                    <Text fontSize="sm" lineHeight="normal">
                      ✨
                    </Text>
                  }
                >
                  Add
                </Button>
              }
            />
          </MotionBox>
        )}
      </AnimatePresence>

      <SendControl />
    </Flex>
  );
};
