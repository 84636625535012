import { useMemo } from 'react';
import { useEntriesContext } from '@/pages/Schedule/contexts';
import { useAvailabilityResponses } from '@/pages/Schedule/views/ConflictView/hooks/useAvailabilityResponses';
import { groupByResponder } from '../../utils';
import { CardList } from '../CardList';
import { ResponseCard } from './ResponseCard';

export const ResponseConflictList = () => {
  const { entryInstances, entriesMap } = useEntriesContext();
  const { data: responses } = useAvailabilityResponses();
  const results = useMemo(() => {
    if (!responses) {
      return [];
    }

    return groupByResponder(entriesMap, entryInstances, responses);
  }, [entriesMap, entryInstances, responses]);

  return (
    <CardList>
      {results.map((group) => (
        <ResponseCard group={group} key={group.id} />
      ))}
    </CardList>
  );
};
