type Props = {
  className?: string;
};

export const PartialConflictIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      viewBox="-1 -1 17 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16121 17.0514C7.05967 17.0514 6.02308 16.841 5.05144 16.4201C4.0798 16.0044 3.22247 15.4251 2.47945 14.6821C1.74163 13.9391 1.16228 13.0817 0.741414 12.1101C0.320543 11.1385 0.110107 10.1019 0.110107 9.00032C0.110107 7.89878 0.320543 6.86219 0.741414 5.89055C1.16228 4.91891 1.74163 4.06418 2.47945 3.32636C3.22247 2.58334 4.0772 2.0014 5.04365 1.58053C6.01529 1.15965 7.05188 0.949219 8.15342 0.949219C9.26015 0.949219 10.2993 1.15965 11.271 1.58053C12.2426 2.0014 13.0999 2.58334 13.843 3.32636C14.586 4.06418 15.1679 4.91891 15.5888 5.89055C16.0097 6.86219 16.2201 7.89878 16.2201 9.00032C16.2201 10.1019 16.0097 11.1385 15.5888 12.1101C15.1679 13.0817 14.586 13.9391 13.843 14.6821C13.0999 15.4251 12.2426 16.0044 11.271 16.4201C10.2993 16.841 9.26275 17.0514 8.16121 17.0514ZM8.16121 15.4615C9.06011 15.4615 9.89925 15.2952 10.6786 14.9627C11.458 14.6301 12.1439 14.1677 12.7362 13.5753C13.3286 12.983 13.791 12.2971 14.1235 11.5178C14.4561 10.7384 14.6224 9.89922 14.6224 9.00032C14.6224 8.10662 14.4561 7.27008 14.1235 6.49068C13.791 5.7061 13.3286 5.01764 12.7362 4.4253C12.1439 3.83296 11.4554 3.37053 10.6708 3.03799C9.89146 2.70545 9.05231 2.53918 8.15342 2.53918C7.25972 2.53918 6.42057 2.70545 5.63599 3.03799C4.8566 3.37053 4.17333 3.83296 3.58619 4.4253C2.99905 5.01764 2.53921 5.7061 2.20667 6.49068C1.87413 7.27008 1.70786 8.10662 1.70786 9.00032C1.70786 9.89922 1.87413 10.7384 2.20667 11.5178C2.53921 12.2971 2.99905 12.983 3.58619 13.5753C4.17853 14.1677 4.86439 14.6301 5.64378 14.9627C6.42837 15.2952 7.26751 15.4615 8.16121 15.4615Z"
        fill="currentColor"
      />
      <path
        d="M10.0863 10.3357C9.73818 10.3357 9.39784 10.2733 9.0653 10.1486C8.73796 10.0187 8.41581 9.87324 8.09886 9.71217C7.7871 9.5459 7.47794 9.40041 7.17138 9.27571C6.86482 9.151 6.55826 9.08865 6.2517 9.08865C5.77368 9.08865 5.358 9.17698 5.00468 9.35365C4.65136 9.53031 4.34999 9.76153 4.10059 10.0473V8.54308C4.34999 8.27289 4.65915 8.04947 5.02806 7.8728C5.39697 7.69095 5.83863 7.60002 6.35302 7.60002C6.70115 7.60002 7.03889 7.66497 7.36623 7.79487C7.69877 7.91957 8.02352 8.06505 8.34047 8.23132C8.65742 8.3924 8.96658 8.53529 9.26795 8.65999C9.5745 8.78469 9.88107 8.84704 10.1876 8.84704C10.6657 8.84704 11.0813 8.75871 11.4347 8.58205C11.7932 8.40539 12.0945 8.17417 12.3387 7.88839V9.39262C12.0945 9.65761 11.788 9.88103 11.4191 10.0629C11.0502 10.2447 10.6059 10.3357 10.0863 10.3357Z"
        fill="currentColor"
      />
    </svg>
  );
};
