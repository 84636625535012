import { useState } from 'react';
import { DeleteEntryConfirmation } from '@/pages/Schedule/components';
import { useEntriesContext } from '@/pages/Schedule/contexts';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import type { ViewProps } from '@/pages/Schedule/views/types';
import { Divider, Flex, Stack, StackDivider } from '@/ui';
import { EntryItem } from './EntryItem';

type Props = Pick<ViewProps, 'onInstanceClick'>;

export const EntryList = ({ onInstanceClick }: Props) => {
  const { entriesMap, entryInstances } = useEntriesContext();
  const [confirmDelete, setConfirmDelete] = useState<{
    entry: DecoratedEntry;
    instance: DecoratedInstance;
  } | null>(null);

  return (
    <>
      {confirmDelete && (
        <DeleteEntryConfirmation
          entry={confirmDelete.entry}
          instance={confirmDelete.instance}
          isOpen
          onClose={() => setConfirmDelete(null)}
        />
      )}

      <Flex direction="column" gap="4">
        <Divider />

        <Stack divider={<StackDivider />} spacing="4">
          {entryInstances.map((instance) => {
            const entry = entriesMap[instance.parentId];
            if (!entry) {
              return null;
            }

            return (
              <EntryItem
                entry={entry}
                instance={instance}
                key={instance.id}
                onDelete={() => setConfirmDelete({ entry, instance })}
                onEdit={onInstanceClick}
              />
            );
          })}
        </Stack>
      </Flex>
    </>
  );
};
