import { AnimateChangeInHeight } from '@/components/AnimateChangeInHeight';
import { NotesButton } from '@/pages/Schedule/components';
import type { DecoratedInstance } from '@/pages/Schedule/types';
import { useAvailabilityContext } from '@/pages/Schedule/views/ConflictView/context/AvailabilityContext';
import { AvailabilityType } from '@/types/gql.generated';
import { Flex } from '@/ui';
import { ConflictButton } from './ConflictButton';

type Props = {
  instance: DecoratedInstance;
};

export const RespondControls = ({ instance }: Props) => {
  const { getConflictForInstance, setAvailability } = useAvailabilityContext();
  const conflict = getConflictForInstance(instance);

  return (
    <AnimateChangeInHeight>
      <Flex
        align="flex-start"
        direction="column"
        gap="3"
        pl={{ base: '10', md: undefined }}
      >
        <Flex gap="2" justify="flex-start">
          <ConflictButton
            availabilityType={AvailabilityType.Full}
            bg="customgray.dark"
            instance={instance}
            isAlternate={Boolean(
              conflict && conflict.status !== AvailabilityType.Full
            )}
          />
          <ConflictButton
            availabilityType={AvailabilityType.Partial}
            bg="customgray.dark"
            instance={instance}
            isAlternate={Boolean(
              conflict && conflict.status !== AvailabilityType.Partial
            )}
          />
        </Flex>

        {conflict && (
          <NotesButton
            flip={false}
            note={conflict.note || ''}
            onSubmit={(value, close) => {
              setAvailability(instance, { note: value });
              close();
            }}
          />
        )}
      </Flex>
    </AnimateChangeInHeight>
  );
};
