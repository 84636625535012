import { AiOutlinePlus } from 'react-icons/ai';
import { AppLoader } from '@/components/AppLoader';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { EmptyState } from '@/pages/Schedule/components';
import { AddContentButton } from '@/pages/Schedule/components/AddContentButton';
import { useEntriesContext } from '@/pages/Schedule/contexts';
import type { ViewProps } from '@/pages/Schedule/views/types';
import { Button, Flex, Icon } from '@/ui';
import { Mode, useExecutionContext } from '../../context/ExecutionContext';
import { ControlBar } from './components/ControlBar';
import { Controls } from './components/Controls';
import { EntryList } from './components/EntryList';
import { SubmissionView } from './components/SubmissionView';

export const ScheduleView = ({ onInstanceClick }: ViewProps) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { entryInstances, isLoading } = useEntriesContext();
  const { mode } = useExecutionContext();

  if (mode === Mode.Submit) {
    return <SubmissionView />;
  }

  const hasControlBar = mode !== Mode.Author || isMobileBreakpoint;

  return (
    <>
      <Flex
        direction="column"
        gap="6"
        pb={hasControlBar ? { base: '24', md: '8' } : 0}
      >
        {entryInstances.length > 0 ? (
          <EntryList onInstanceClick={onInstanceClick} />
        ) : isLoading ? (
          <AppLoader />
        ) : (
          <EmptyState
            cta={
              mode !== Mode.Author ? null : (
                <AddContentButton
                  buttonComponent={
                    <Button
                      leftIcon={<Icon as={AiOutlinePlus} />}
                      variant="secondary"
                    >
                      Add item
                    </Button>
                  }
                />
              )
            }
          />
        )}
      </Flex>

      {hasControlBar && (
        <ControlBar>
          <Controls />
        </ControlBar>
      )}
    </>
  );
};
